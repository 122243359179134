import React from "react"
import Container from "../../container"
import ctl from "@netlify/classnames-template-literals"
import Button from "../../button"
import ellipseIcon from "icons/targets-ellipse.svg"
import { headingStyle } from "./style"
import Footballer from "../../../animations/footballer"

const HitYourTradingTargets = () => {
  return (
    <Container size="medium" className={containerStyle}>
      <div className={contentSectionStyle}>
        <h2 className={headingStyle}>
          Hit your Trading <br />
          targets
          <img src={ellipseIcon} alt="" className={ellipseIconStyle} />
        </h2>
        <p className="text-lg leading-[25px]">
          Sign up to Egmarkets’ free daily <br />
          signals and score trade after trade <br />
          with relative ease
        </p>
        <Button
          label="Subscribe here"
          type={null}
          hasArrow
          arrowBlue
          className="!text-lg text-blue border-none md:!pl-0 font-semibold inline-flex items-center"
          url="http://eepurl.com/hDPu1X"
          hasMargin={false}
          isExternal
        />
      </div>
      <div className={imageWrapperStyle}>
        <Footballer/>
      </div>
    </Container>
  )
}

export default HitYourTradingTargets

const ellipseIconStyle = ctl(
  `shape absolute bottom-0 lg:-ml-3 ml-9 md:w-auto w-[250px]`
)
const imageWrapperStyle = ctl(
  `xl:w-6/12 md:w-8/12 lg:max-w-[692px] md:mt-0 mt-12 
  transform md:translate-y-0 translate-y-[-50px]
  translate-x-[50px] md:translate-x-[0px]  
  lg:absolute lg:top-[2.5%] top-[0%] lg:right-[6%]
  w-[120%] md:w-unset
  relative
  `
)

const contentSectionStyle = ctl(`max-w-[360px] relative md:pl-6 md-max:mb-8`)

const containerStyle = ctl(
  `flex lg:flex-row flex-col justify-between items-center lg:mb-[11rem] xl:mb-[17.75rem] mb-12`
)
