import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ButtonGroup from "../../../utils/button/group"
import { eagletradeButtons } from "./chart"

const EagletradePlatformChartMobile = () => {
  return (
    <div className='md:hidden pt-[176px] mt-[-107px] pb-[60px] mb-[60px] bg-light-tangerine border-t border-b border-brownish-grey'>
      <div className='flex pl-[33px] gap-x-[10px]'>
        <p className='mt-[50px] text-base'>
          Our market leading charts provide a full suite of indicators and tools for the statistical analyst and the fundamentals are covered with the integrated rolling news and our economic calendar.
        </p>
        <StaticImage
          src="../../../../assets/images/pages/eagletrade/eagletrade-chart-mobile.webp"
          alt="Metatrader 4 chart"
          className='w-[175px] flex-shrink-0'
        />
      </div>
      <div className='md-max:pt-[30px]'>
        <ButtonGroup className='md-max:gap-y-[20px]' fullWidthOnMobile buttons={eagletradeButtons} />
      </div>
    </div>
  )
}

export default EagletradePlatformChartMobile