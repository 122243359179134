import React from "react"
import Flex from "../../../utils/flex"
import ctl from "@netlify/classnames-template-literals"
import { DesktopBreakpoint } from "../../../breakpoints"
import ComingSoonBadge from "../../../coming-soon-badge"
import EagletradeAnimation from "../../../animations/eagletrade-animation"

const CloudtradePlatformIntro = () => {
  return (
    <>
      <Flex
        alignTop
        className='md:!pt-[80px] md-max:w-full md-max:pb-0 md-max:pt-[40px] md:pl-[28px] md:w-full'
        firstItem={
          <>
            <h1 className='md-max:justify-center font-extrabold flex items-center gap-x-5 text-[32px] md:text-[40px]'>
              <span>
                Eagletrade
              </span>
              <ComingSoonBadge/>
            </h1>
            <p className='md-max:hidden'>
              Feel at home with the Egmarkets’ Eagletrade platform. <DesktopBreakpoint/>
              Our ultimate multi-asset platform allows you to trade the <DesktopBreakpoint/>
              financial markets in YOUR local currency.
            </p>
            <DesktopBreakpoint/>
            <p className='md-max:hidden mt-0'>
              The browser-based platform can be used on your PC or<DesktopBreakpoint/>
              on your mobile phone by downloading the Egmarkets<DesktopBreakpoint/>
              mobile app from the <a href="https://play.google.com/store/apps/details?id=au.com.finsa.egm" rel="noreferrer"  target="_blank" className='text-button-blue no-underline'>Play Store.</a>
            </p>
            <p className='whitespace-pre-wrap md:hidden mt-30px] max-w-[321px]'>
              Feel at home with the Egmarkets’ Cloudtrade
              platform. Our ultimate multi-asset platform
              allows you to trade in YOUR local currency.
            </p>
            <p className='whitespace-pre-wrap mt-[20px] md:hidden max-w-[321px]'>
              The browser-based platform can be used on
              your PC or on your mobile phone by
              downloading the Egmarkets mobile app
              from the <a href="https://play.google.com/store/apps/details?id=au.com.finsa.egm" rel="noreferrer"  target="_blank" className='text-button-blue no-underline'>Play Store.</a>
            </p>
          </>
        }
        secondItem={
          <div className={imageStyle}>
            <EagletradeAnimation />
          </div>
        }
        secondItemClassName='mt-[60px]'
      />
    </>
  )
}

const imageStyle = ctl(
  `md:w-[429px] z-10 md-max:max-w-[313px] md:ml-auto md:mr-0 ml-auto mr-auto md:transform translate-y-[-20px]`
)

export default CloudtradePlatformIntro
