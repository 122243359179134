import * as React from "react"
import Layout from "../../components/layout"
import { ReceiveTradingSupport } from "components/utils/trading"
import { EagletradePlatformChart, EagletradePlatformIntro } from "../../components/pages/platforms/cloudtrade"
import Container from "../../components/utils/container"
import PlatformAppDownload from "../../components/pages/platforms/app-download"
import EagletradePlatformChartMobile from "../../components/pages/platforms/cloudtrade/chart-mobile"

const EagletradePlatformPage = () => (
  <Layout
    title="Eagletrade - Trade the financial market using Naira"
    description='Download Eagletrade to start trading the financial markets using Naira.'
    disableOverflow
  >
    <div className="relative overflow-hidden">
      <Container size="large">
        <EagletradePlatformIntro />
      </Container>
    </div>
    <EagletradePlatformChart />
    <EagletradePlatformChartMobile/>
    <div className="relative">
      <ReceiveTradingSupport withMargin />
      <PlatformAppDownload page="Eagletrade" />
    </div>

  </Layout>
)

export default EagletradePlatformPage
