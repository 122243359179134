import React from "react"
import Flex from "../../../utils/flex"
import { StaticImage } from "gatsby-plugin-image"
import ctl from "@netlify/classnames-template-literals"
import ButtonGroup from "../../../utils/button/group"
import { Container } from "../../../utils"

const EagletradePlatformChart = () => {
  return (
    <div className="md-max:hidden bg-light-tangerine md:pt-[86px] mt-[-104px] md:pb-[40px] border-t border-b border-brownish-grey">
      <Container size="large">
        <Flex
          alignTop
          className="!pt-0 md-max:items-start md-max:justify-start !w-full md:pl-[28px]"
          firstItemClassName="md-max:absolute transform md-max:translate-y-2 md-max:right-[-300px] md-max:w-[484px]"
          secondItemClassName="md-max:w-full md-max:mt-0"
          firstItem={
            <StaticImage
              src="../../../../assets/images/pages/eagletrade/eagletrade-chart.webp"
              alt="Cloudtrade"
              className={imageStyle}
            />
          }
          secondItem={
            <>
              <p className="md-max:pt-[50px] mt-0 md:mt-[48px] md-max:w-[206px] md-max:text-left md-max:mb-[80px]">
                Our market leading charts provide a full suite of <br />
                indicators and tools for the statistical analyst and the <br />
                fundamentals are covered with the integrated rolling <br />
                news and our economic calendar.
              </p>
              <ButtonGroup buttons={eagletradeButtons} />
            </>
          }
        />
      </Container>
    </div>
  )
}

export const eagletradeButtons = [
  {
    label: "Trade on Eagletrade",
    url: "/404",
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  },
  {
    label: "Try Demo Trading",
    isExternal: true,
    url: "https://mt4.egmarkets.trade/",
    className: 'bg-transparent md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  }
]

const imageStyle = ctl(`lg:mt-0 md:mt-[60px] max-w-[532px] md:left-[-28px]`)

export default EagletradePlatformChart
