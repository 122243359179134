import React from "react"
import TargetsMarketUpdates from "./market-updates"
import shape from "assets/images/components/hit-your-targets/shape.svg"
import ctl from "@netlify/classnames-template-literals"
import HitYourTradingTargets from "./hit-targets"

const TradingTargets = ({ mergeWithTop = false, className }) => {
  const wrapperStyle = ctl(
    `relative mt-[10px] md:mt-[-70px] md:mb-0 mb-8 pb-0 
    overflow-hidden lg:text-left text-center text-[17px]`
  )
  const shapeStyle = ctl(`absolute max-w-screen-lg`)
  const topShapeStyle = ctl(
    `${shapeStyle} 
     lg:w-[65%] sm:w-[1600px] w-[800px] sm:top-[20%] top-[280px] z-1
     3xl:max-w-none 3xl:top-[20%] 3xl:left-[4%]
     `
  )

  return (
    <div className={`md:border-t border-b border-brownish-grey bg-light-tangerine ${className} ${mergeWithTop ? 'md:pt-[458px] pt-[50px] md:mt-[-440px]' : 'pt-[140px] mt-0'} mb-[40px] md:mb-[110px]`}>
      <div className={wrapperStyle}>
        <img src={shape} alt="" className={topShapeStyle} />
        <div className='z-10 relative'>
          <HitYourTradingTargets />
          <TargetsMarketUpdates />
        </div>
      </div>
    </div>
  )
}

export default TradingTargets
