import React from "react"
import ctl from "@netlify/classnames-template-literals"

const ComingSoonBadge = ({ className = '' }) => {
  return (
    <span className={`${comingSoonStyle} ${className}`}>
      Coming Soon
    </span>
  )
}

const comingSoonStyle = ctl(`
  text-[11px] leading-[16px]
  md:leading-[23px] md:text-base
  inline-block px-[14px] 
  py-[5px] rounded-[30px] 
  bg-button-blue font-bold font-hkgrotesk text-white
`)


export default ComingSoonBadge