import React from "react"
import Container from "../../container"
import ctl from "@netlify/classnames-template-literals"
import Button from "../../button"
import badgeShape from "assets/icons/badge.svg"
import { headingStyle } from "./style"
import ReceiveMarketUpdates from "../../../animations/receive-market-updates"

const TargetsMarketUpdates = () => {
  return (
    <Container size="medium" className={containerStyle}>
      <div className={imageWrapperStyle}>
        <figure className={imageStyle}>
          <ReceiveMarketUpdates />
        </figure>
      </div>
      <div className={contentSectionStyle}>
        <h2 className={headingStyle}>
          Receive Financial  Market updates
          <img src={badgeShape} alt="" className={badgeShapeStyle} />
        </h2>
        <p className='text-[17px] leading-[24px]'>
          Never miss important market updates. <br/>
          Get push notifications on the go and take <br/>
          advantage of price movements promptly
        </p>
        <Button
          arrowBlue
          className='!text-lg text-blue border-none md:!pl-0 font-semibold inline-flex items-center'
          type={null}
          label="See how"
          hasArrow
          hasMargin={false}
          url='https://egmarkets.medium.com/how-to-add-egmarkets-as-an-home-screen-app-on-android-ios-9e8af26bbea7'
          isExternal
        />
      </div>
    </Container>
  )
}

const imageWrapperStyle = ctl(`md:w-6/12`)
const imageStyle = ctl(
  ` xl:transform 
  xl:translate-y-[-6.7rem]
  2xl:translate-y-[-3.2rem]
  3xl:translate-y-0
  lg:translate-y-[-4rem]
  lg:translate-x-[6.7rem] 
  md-max:mx-auto text-center sm:w-[390px] w-[260px] 
  transform translate-y-[-80px] md:translate-y-0
  `
)

//https://github.com/NekR/self-destroying-sw/tree/master/packages/gatsby-plugin-remove-serviceworker

const contentSectionStyle = ctl(`max-w-[360px] md-max:mt-[-50px] lg:mt-[-56px] `)

const containerStyle = ctl(
  `flex lg:flex-row flex-col justify-between items-center lg:mb-16 pt-6`
)

const badgeShapeStyle = ctl(
  `shape absolute top-[-16px] 
  lg:right-[80px] right-[32px] w-[54px] `
)
export default TargetsMarketUpdates
