import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import InViewLottie from "../in-view-lottie"

const EagletradeAnimation = () => {
  const { eagletrade: { data } } = useStaticQuery(graphql`
    {
      eagletrade{
        data
      }
    }
  `)

  return (
    <InViewLottie width='100%' animationData={JSON.parse(data)} />
  )
}

export default EagletradeAnimation