import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import InViewLottie from "../in-view-lottie"

const Footballer = () => {
  const { footballer: { data } } = useStaticQuery(graphql`
    {
      footballer{
        data
      }
    }
  `)

  return (
    <InViewLottie loop animationData={JSON.parse(data)} />
  )
}

export default Footballer